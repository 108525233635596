<template>
  <div class="progress-wrapper">
    <div class="file-info">
      <img :src="assets.file" />
      <span class="text-ellipsis"> {{ fileName }} </span>
      <i class="el-icon-download download-icon" v-if="showFileDownload" @click="download"></i>
    </div>

    <div class="progress-toolbar">
      <div class="tip">
        <span class="pr10">
          <template v-if="isServer"> 已完成 </template>
          <template v-else-if="file.status === 'success'"> 已完成 </template>
          <template v-else-if="file.status === 'uploading'"> 上传中 </template>
          <template v-else-if="file.status === 'ready'"> 上传中 </template>
          <template v-else> 上传失败 </template>
        </span>
        <span> {{ size }} </span>
      </div>

      <div v-if="!isServer" class="action">
        <i class="el-icon-refresh-right" v-if="operation && file.status === 'fail'" @click="retry"></i>
        <i class="el-icon-close ml10" v-if="operation" @click="remove"></i>
      </div>
    </div>
    <template v-if="isServer">
      <el-progress :percentage="100" :show-text="false" class="progress-success"></el-progress>
    </template>
    <template v-else>
      <el-progress :percentage="percentage" :show-text="false" :class="'progress-' + file.status"></el-progress>
    </template>
  </div>
</template>

<script>
import file from '@/assets/images/file.png'

export default {
  name: 'Progress',
  props: {
    // 上传的文件信息
    file: {
      type: Object,
      required: true
    },
    // 是否是服务器返回的文件信息
    // 编辑信息时，服务器返回的已上传文件格式，与前端生成的文件对象格式不同，需要区别处理
    // true: 是服务器格式  false: 前端生成的格式
    isServer: {
      type: Boolean,
      default: false
    },
    // 是否可操作 不可操作时，不展示删除和重试按钮
    operation: {
      type: Boolean,
      default: true
    },
    // 进度
    percentage: {
      type: Number,
      required: true
    },
    // 0: 执行/上传中  1: 成功  2: 失败
    status: {
      type: Number,
      default: 1
    },
    // 各个文件显示下载按钮
    showFileDownload: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fileName() {
      if (this.file.fileName) return this.file.fileName
      if (this.file.name) return this.file.name
      return ''
    },
    size() {
      if (this.file.fileSize) return this.file.fileSize

      if (!this.file.size || this.file.sie === 0) return '0'

      let size = this.file.size

      if (size < 1024) return size + 'B'

      size = Math.ceil(this.file.size / 1024)
      if (size < 1024) return size + 'K'

      size = Math.ceil(this.file.size / (1024 * 1024))
      if (size < 1024) return size + 'M'

      size = Math.ceil(this.file.size / (1024 * 1024 * 1024))
      return size + 'G'
    }
  },
  created() {
  },
  data() {
    return {
      assets: {
        file: file
      }
    }
  },
  methods: {
    retry() {
      this.$emit('retry')
    },
    remove() {
      this.$emit('remove')
    },
    download() {
      if (!this.file.fileAddr) return

      const link = document.createElement('a')
      link.style.display = 'none'
      link.target = '_blank'
      link.href = this.file.fileAddr
      link.download = ''
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-wrapper {
  padding: 20px 5px 10px;

  .file-info {
    display: flex;
    align-items: center;
    padding: 10px 0;

    img {
      &:nth-child(1) {
        width: 27px;
        margin-right: 10px;
      }
    }
    span {
      font-size: 12px;
      flex-grow: 1;
    }
    .download-icon {
      cursor: pointer;
      color: rgba(64, 145, 247, 1);
      font-size: 20px;
    }
  }
  .progress-toolbar {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, .6);

    .tip {
      display: flex;
      font-size: 12px;
      line-height: 21px;
    }
    .action {
      i {
        cursor: pointer;

        &:hover {
          color: rgba(0, 0, 0, .8);
        }
      }
    }
  }
  .progress-success {
    /deep/ .el-progress-bar__inner {
      background-color: rgba(64, 145, 247, 1);
    }
  }
  .progress-uploading {
    /deep/ .el-progress-bar__inner {
      background-color: rgba(101, 195, 101, 1);
    }
  }
  .progress-fail {
    /deep/ .el-progress-bar__inner {
      background-color: rgba(236, 77, 61, 1);
    }
  }
}
</style>
