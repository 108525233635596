<template>
	<div class="upload-wrapper">
		<el-upload ref="upload" drag multiple :disabled="disabled" :limit="limit" :action="action" :headers="headers" :file-list="files"
			:show-file-list="false" :before-upload="handleBeforeUpload" :on-change="handleChange"
			:on-progress="handleProgress" :on-error="handleError" :on-success="handleSuccess" :http-request="handleUpload">
			<div class="upload-info">
				<div class="upload-image mr8">
					<img :src="assets.upload" />
				</div>
				<div class="upload-tip">
					<div class="message info-text"> 点击或将文件拖拽到这里上传 </div>
					<div class="tip"> {{ message }} </div>
				</div>
			</div>
		</el-upload>
	</div>
</template>

<script>
	import md5 from 'blueimp-md5'
	import shangchuan from '@/assets/images/shangchuan.png'
	import OSS from 'ali-oss'

	const defaultSettings = require('@/settings.js')
	const url = defaultSettings.hostName

	export default {
		name: 'UploadOss',
		props: {
			// el-upload 的文件列表
			files: {
				type: Array,
				default: () => {
					return []
				}
			},
			// 上传失败的文件列表
			failedFiles: {
				type: Array,
				default: () => {
					return []
				}
			},
			// 是否禁用
			disabled: {
				type: Boolean,
				default: false
			},
			// 上传数
			limit: {
				type: Number,
				default: 100
			},
			message: {
				type: String,
				default: '支持扩展名: .rar .zip .doc .docx .pdf .jpg...'
			},
			// 上传至OSS
			useOss: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				assets: {
					upload: shangchuan
				},
				fileList: [],
				failedFileList: [],
				ossUploadUrl: '',
			}
		},
		computed: {
			action() {
				if (this.useOss) {
					if (this.ossUploadUrl) return this.ossUploadUrl
					return url + "bsi/sysAttachFile/addFileOss"
				}
				return url + "bsi/sysAttachFile/addFile"
			},
			headers() {
				const token = sessionStorage.getItem('token') || ''

				return {
					token: token
				}
			},
			// 是否是超级管理员
			isSuperAdmin() {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false
				if (userInfo.superAdminFlag === '1') return true

				return false
			},
		},
		watch: {
			fileList(files) {
				this.$emit("update:files", files)
			},
			failedFileList(files) {
				this.$emit("update:failedFiles", files)
			}
		},
		methods: {
			handleBeforeUpload(file) {
				console.log('handle before upload')
				if (this.isSuperAdmin) {
					// 超管单个文件大小限制1G
					const limitSize = file.size / 1024 / 1024 / 1024 <= 1
					if (!limitSize) {
						this.$message.warning('文件大小不能超过1G')
						return false
					}
				} else {
					// 非超管单个文件大小限制255M
					// 2022-07-31 应客户要求改为200M
					const limitSize = file.size / 1024 / 1024 <= 200
					if (!limitSize) {
						this.$message.warning('文件大小不能超过200M')
						return false
					}
				}

				const fileMd5 = md5(file)

				for (let f of this.failedFileList) {
					if (!f.md5) f.md5 = md5(f.raw)

					// 相同文件判断
					if (f.md5 === fileMd5 && f.name === file.name && f.uid === file.uid) {
						this.failedFileList.splice(this.failedFileList.indexOf(f), 1)
						break
					}
				}
			},
			handleChange(file, fileList) {
				console.log('handle change file: ', file)
				if (!file.md5) {
					file.md5 = md5(file.raw)
					console.log('md5: ', file.md5)
				}
				const notFailFiles = []
				for (const file of fileList) {
					if (file.status !== 'fail') {
						notFailFiles.push(file)
					}
				}
				this.fileList = notFailFiles
			},
			handleProgress(event, file, fileList) {
				// if (!file.md5) file.md5 = md5(file.raw)
				// if (response && response.code != '0') {
				// 	file.status = 'fail'

				// 	const inFailList = false
				// 	for (const f of this.failedFileList) {
				// 		if (file.md5 === f.md5) {
				// 			inFailList = true
				// 		}
				// 	}
				// 	if (!inFailList) this.failedFileList.push(file)
				// }

				// const successFiles = []
				// for (const file of fileList) {
				// 	if (file.status === 'success') {
				// 		successFiles.push(file)
				// 	}
				// }
				// this.fileList = successFiles
			},
			// element ui upload 上传失败会从文件列表中删除该文件
			handleError(err, file, fileList) {
				this.failedFileList.push(file)
			},
			handleSuccess(response, file, fileList) {
				if (!file.md5) file.md5 = md5(file.raw)
				if (response && response.code != '0') {
					file.status = 'fail'

					const inFailList = false
					for (const f of this.failedFileList) {
						if (file.md5 === f.md5 && file.uid === f.uid) {
							inFailList = true
						}
					}
					if (!inFailList) this.failedFileList.push(file)
				}

				const successFiles = []
				for (const file of fileList) {
					if (file.status === 'success') {
						successFiles.push(file)
					}
				}
				this.fileList = successFiles
			},
			submit() {
				this.$refs.upload.clearFiles()
				this.$refs.upload.uploadFiles = this.files
				this.$refs.upload.submit()
			},
			genFileSuffix() {
				const now = new Date()
				let month = now.getMonth() + 1
				month = month.toString().padStart(2, '0')

				let day = now.getDate()
				day = day.toString().padStart(2, '0')

				let hour = now.getHours()
				hour = hour.toString().padStart(2, '0')

				let minute = now.getMinutes()
				minute = minute.toString().padStart(2, '0')

				let second = now.getSeconds()
				second = second.toString().padStart(2, '0')

				return `_${month}${day}${hour}${minute}${second}`
			},
			handleUpload(data) {
				const file = data.file
				let fileName = file.name
				console.log('file: ', file)

				const fileNameSuffix = this.genFileSuffix()

				const split = file.name.split('.')
				if (split.length === 1) {
					fileName = `${fileName}${fileNameSuffix}`
				} else if (split.length > 1) {
					split[split.length - 2] = `${split[split.length - 2]}${fileNameSuffix}`
					fileName = split.join('.')
				}

				let fileSize
				if (file.size < 1000) {
					fileSize = `${file.size}byte`
				} else if (file.size < 1000000) {
					fileSize = `${(file.size / 1024).toFixed(2)}KB`
				} else {
					fileSize = `${(file.size / 1048576).toFixed(2)}MB`
				}

				console.log('new file name: ', fileName)
				console.log('file size: ', fileSize)

				const filePath = `ricoh/library/${fileName}`

				let that = this
				that.$request.get(
					"getOssTemCredential",
					false,
					{},
					function(r) {
						const credential = r.data

						const client = new OSS({
							// yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
							region: credential.region,
							// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
							accessKeyId: credential.accessKeyId,
							accessKeySecret: credential.accessKeySecret,
							secure: true,
							// 从STS服务获取的安全令牌（SecurityToken）。
							stsToken: credential.securityToken,
							// 填写Bucket名称。
							bucket: credential.bucketName
						})

						// client.put(filePath, file, {
						// 	progress: (p, cpt, res) => {
						// 		// console.log(`p: ${p}, cpt: ${cpt}`)
						// 		// 获取上传进度。
						// 		console.log('progress=====', p)
						// 		console.log('cpt: ', cpt)
						// 		console.log('res: ', res)
						// 	},
						// })
						// 	.then(res => {
						// 		console.log('ali upload done: ', res)

						// 		that.$request.post(
						// 			"sysFileAdd",
						// 			false,
						// 			{
						// 				fileAddr: res.url,
						// 				fileName: fileName,
						// 				fileSize: fileSize
						// 			},
						// 			function (r) {
						// 				console.log('add ok, file: ', file)
						// 				const fileMd5 = md5(file)
						// 				for (const f of that.fileList) {
						// 					if (f.md5 === fileMd5) {
						// 						console.log('匹配到已存在的文件')

						// 						that.$set(f, 'response', r)
						// 						that.$set(f, 'percentage', 100)
						// 						that.$set(f, 'status', 'success')
						// 					}
						// 				}
						// 			},
						// 			function (e) {}
						// 		)
						// 	})
						// 	.catch(error => {
						// 		console.log('ali error: ', error)
						// 		const fileMd5 = md5(file)
						// 		for (const f of that.fileList) {
						// 			if (f.md5 === fileMd5) {
						// 				console.log('匹配到已存在的文件')

						// 				// that.$set(f, 'response', r)
						// 				// that.$set(f, 'percentage', 100)
						// 				that.$set(f, 'status', 'fail')
						// 			}
						// 		}
						// 	})

						client.multipartUpload(filePath, file, {
							progress: (p, cpt, res) => {
								// 获取上传进度。
								// const fileMd5 = md5(file)
								for (const f of that.fileList) {
									// if (f.md5 === fileMd5) {
									if (f.uid === file.uid) {
										console.log('匹配到文件: ', f)
										that.$set(f, 'percentage', Math.round(p * 100))
										that.$set(f, 'status', 'uploading')
									}
								}
							},
							// 设置并发上传的分片数量。
							parallel: 4,
							// 设置分片大小。默认值为1 MB，最小值为100 KB。
							partSize: 1024 * 1024,
						})
							.then(res => {
								console.log('分片上传成功: ', res)
								const url = `https://${credential.bucketName}.${credential.region}.aliyuncs.com/${res.name}`
								console.log('文件路径: ', url)
								that.$request.post(
									"sysFileAdd",
									false,
									{
										fileAddr: url,
										fileName: fileName,
										fileSize: fileSize
									},
									function (r) {
										console.log('add ok, file: ', file)
										// const fileMd5 = md5(file)
										for (const f of that.fileList) {
											// if (f.md5 === fileMd5) {
											if (f.uid === file.uid) {
												that.$set(f, 'response', r)
												that.$set(f, 'percentage', 100)
												that.$set(f, 'status', 'success')
												console.log('文件匹配: ', f)
											}
										}
									},
									function (e) {}
								)
							})
							.catch(error => {
								console.log('分片上传失败: ', error)
								// const fileMd5 = md5(file)
								for (const f of that.fileList) {
									// if (f.md5 === fileMd5) {
									if (f.uid === file.uid) {
										that.$set(f, 'status', 'fail')
										that.failedFileList.push(f)
									}
								}
							})
					},
					function(e) {},
				)
			},
			handleUpload1(data) {
				console.log('handle upload data: ', data)
				const file = data.file
				let that = this
				that.$request.post(
					"getOssPreUploadUrl",
					false,
					{
						filename: file.name
					},
					function (r) {
						const ossUploadUrl = r.data
						console.log('upload url: ', ossUploadUrl)
						// resolve(r)

						const formdata = new FormData()
						formdata.append('file', file)

						that.$request.put(
							ossUploadUrl,
							false,
							formdata,
							function(r) {
								console.log('upload result: ', r)
							},
							function(error) {
								console.log('upload error: ', error)
							}
						)
					},
					function(e) {
						// reject(e)
					}
				)
				
			},
		}
	}

</script>

<style lang="scss" scoped>
	/deep/ .el-upload-dragger {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.upload-wrapper {
		.upload-info {
			display: flex;
			align-items: center;

			.upload-image {
				height: 40px;
			}

			.upload-tip {
				text-align: left;
				line-height: 20px;

				.message {
					font-size: 14px;
				}

				.tip {
					font-size: 12px;
					color: rgba(0, 0, 0, .4);
				}
			}
		}
	}

</style>
