<template>
	<div class="upload-wrapper">
		<el-upload ref="upload" drag multiple :disabled="disabled" :limit="limit" :action="action" :headers="headers" :file-list="files"
			:show-file-list="false" :before-upload="handleBeforeUpload" :on-change="handleChange"
			:on-progress="handleProgress" :on-error="handleError" :on-success="handleSuccess">
			<div class="upload-info">
				<div class="upload-image mr8">
					<img :src="assets.upload" />
				</div>
				<div class="upload-tip">
					<div class="message info-text"> 点击或将文件拖拽到这里上传 </div>
					<div class="tip"> {{ message }} </div>
				</div>
			</div>
		</el-upload>
	</div>
</template>

<script>
	import md5 from 'blueimp-md5'
	import shangchuan from '@/assets/images/shangchuan.png'

	const defaultSettings = require('@/settings.js')
	const url = defaultSettings.hostName

	export default {
		name: 'Upload',
		props: {
			// el-upload 的文件列表
			files: {
				type: Array,
				default: () => {
					return []
				}
			},
			// 上传失败的文件列表
			failedFiles: {
				type: Array,
				default: () => {
					return []
				}
			},
			// 是否禁用
			disabled: {
				type: Boolean,
				default: false
			},
			// 上传数
			limit: {
				type: Number,
				default: 100
			},
			message: {
				type: String,
				default: '支持扩展名: .rar .zip .doc .docx .pdf .jpg...'
			},
			// 上传至OSS
			useOss: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				assets: {
					upload: shangchuan
				},
				fileList: [],
				failedFileList: []
			}
		},
		computed: {
			action() {
				if (this.useOss) return url + "bsi/sysAttachFile/addFileOss"
				return url + "bsi/sysAttachFile/addFile"
			},
			headers() {
				const token = sessionStorage.getItem('token') || ''

				return {
					token: token
				}
			},
			// 是否是超级管理员
			isSuperAdmin() {
				const userInfoStr = window.sessionStorage.getItem('userInfo')
				if (!userInfoStr) return false

				const userInfo = JSON.parse(userInfoStr)
				if (!userInfo) return false
				if (userInfo.superAdminFlag === '1') return true

				return false
			},
		},
		watch: {
			fileList(files) {
				this.$emit("update:files", files)
			},
			failedFileList(files) {
				this.$emit("update:failedFiles", files)
			}
		},
		methods: {
			handleBeforeUpload(file) {
				if (this.isSuperAdmin) {
					// 超管单个文件大小限制1G
					const limitSize = file.size / 1024 / 1024 / 1024 <= 1
					if (!limitSize) {
						this.$message.warning('文件大小不能超过1G')
						return false
					}
				} else {
					// 非超管单个文件大小限制255M
					// 2022-07-31 应客户要求改为200M
					const limitSize = file.size / 1024 / 1024 <= 200
					if (!limitSize) {
						this.$message.warning('文件大小不能超过200M')
						return false
					}
				}

				const fileMd5 = md5(file)

				for (let f of this.failedFileList) {
					if (!f.md5) f.md5 = md5(f.raw)

					// 相同文件判断
					if (f.md5 === fileMd5 && f.name === file.name) {
						this.failedFileList.splice(this.failedFileList.indexOf(f), 1)
						break
					}
				}
			},
			handleChange(file, fileList) {
				if (!file.md5) file.md5 = md5(file.raw)
				const notFailFiles = []
				for (const file of fileList) {
					if (file.status !== 'fail') {
						notFailFiles.push(file)
					}
				}
				this.fileList = notFailFiles
			},
			handleProgress(event, file, fileList) {},
			// element ui upload 上传失败会从文件列表中删除该文件
			handleError(err, file, fileList) {
				this.failedFileList.push(file)
			},
			handleSuccess(response, file, fileList) {
				if (!file.md5) file.md5 = md5(file.raw)
				if (response && response.code != '0') {
					file.status = 'fail'

					const inFailList = false
					for (const f of this.failedFileList) {
						if (file.md5 === f.md5) {
							inFailList = true
						}
					}
					if (!inFailList) this.failedFileList.push(file)
				}

				const successFiles = []
				for (const file of fileList) {
					if (file.status === 'success') {
						successFiles.push(file)
					}
				}
				this.fileList = successFiles
			},
			submit() {
				this.$refs.upload.clearFiles()
				this.$refs.upload.uploadFiles = this.files
				this.$refs.upload.submit()
			}
		}
	}

</script>

<style lang="scss" scoped>
	/deep/ .el-upload-dragger {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.upload-wrapper {
		.upload-info {
			display: flex;
			align-items: center;

			.upload-image {
				height: 40px;
			}

			.upload-tip {
				text-align: left;
				line-height: 20px;

				.message {
					font-size: 14px;
				}

				.tip {
					font-size: 12px;
					color: rgba(0, 0, 0, .4);
				}
			}
		}
	}

</style>
