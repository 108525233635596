<template>
	<div class="page edit-page">
		<div class="top-wrapper">
			<div class="title"> {{ 85 > scrollTop ? title : ''}} </div>
			<div class="actions">
				<el-button type="primary" plain size="small" class="cancel" @click="goBack"> 取消 </el-button>
				<el-button type="primary" size="small" class="submit" @click="submit"> 确定 </el-button>
			</div>
		</div>

		<div class="content-wrapper">
			<el-row :gutter="20" type="flex" class="content-row">
				<el-col :lg="15" :md="15" :sm="15" :xs="24">
					<div class="form-container pa20 pb0">
						<el-form ref="form" :model="form" :rules="rules">
							<el-form-item label="机种分类" prop="modelType">
								<el-select v-model="form.modelType" multiple filterable placeholder="请选择机种分类" :disabled="Boolean(id)" class="full-width">
									<el-option v-for="option in modelDevices" :key="option.id" :label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="机型名称" prop="deviceIdList">
								<el-cascader :options="deviceOptions" :props="machineProps" :show-all-levels="false"
									v-model="form.deviceIdList" clearable @visible-change="deviceCascaderVisableChange"
									:popper-class="deviceClass" class="full-width"></el-cascader>
							</el-form-item>
							<el-form-item label="资料类型" prop="materialType">
								<el-select v-model="form.materialType" filterable placeholder="请选择资料类型" class="full-width" @change="handleGujian">
									<el-option v-for="option in resourceTypeOptions" :key="option.id"
										:label="option.name" :value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="资料名称" prop="materialName">
								<el-input v-model="form.materialName" maxlength="32" placeholder="请输入资料名称"
									class="full-width" @blur="form.materialName = form.materialName.trim()">
								</el-input>
							</el-form-item>
							<el-form-item label="固件下载地址" v-if="form.materialType == '1428012982556266497'">
								<div class="inline-form-item full-width">
									<el-form ref="firmware-form" :model="downloadItem" :rules="firmwareRules"
										class="firmware-form">
										<el-form-item>
											<el-input v-model="downloadItem.firmwareName" placeholder="请输入文字描述"
												clearable class="mr10 show-word-clearable-pr-2" maxlength="50"
												show-word-limit
												@blur="downloadItem.firmwareName=downloadItem.firmwareName.trim()">
											</el-input>
										</el-form-item>
										<el-form-item prop="firmwareLink">
											<el-input v-model="downloadItem.firmwareLink"
												placeholder="请输入Link(http://... 或 https://...)" clearable
												maxlength="2000" class="mr10"
												@blur="downloadItem.firmwareLink=downloadItem.firmwareLink.trim()">
											</el-input>
										</el-form-item>
									</el-form>
									<el-button class="firmware-btn"
										:disabled="!downloadItem.firmwareName || !downloadItem.firmwareLink"
										@click="addDownloadItem"> 添加 </el-button>
								</div>
								<div v-for="(downloadItem, index) in form.firmwareList" :key="index"
									class="link-wrapper">
									<el-tag type="info" closable class="link-tag"
										@close="removeDownloadItem(downloadItem)">
										{{ downloadItem.firmwareName }} : {{ downloadItem.firmwareLink }}
									</el-tag>
								</div>
							</el-form-item>
							<el-form-item label="上传附件" prop="fileIdList">
								<div @click="handleUpload">
									<!-- <Upload ref="upload" :files.sync="uploadNormalFiles" :disabled="uploadDisabled"
									:failedFiles.sync="uploadFailedFiles" :limit="limit" useOss class="full-width"></Upload> -->
									<UploadOss ref="upload" :files.sync="uploadNormalFiles" :disabled="uploadDisabled"
									:failedFiles.sync="uploadFailedFiles" :limit="limit" useOss class="full-width"></UploadOss>
								</div>
							</el-form-item>
							<el-form-item label="备注" prop="remark">
								<el-input
									resize="none"
									type="textarea"
									:rows="4"
									maxlength="255"
									placeholder="备注"
									v-model="form.remark">
								</el-input>
							</el-form-item>
						</el-form>
					</div>
				</el-col>

				<el-col :lg="9" :md="9" :sm="9" :xs="24">
					<div class="upload-container pa20 pb0">
						<div class="title">
							附件内容
						</div>

						<div v-if="uploadFiles.length > 0">
							<template v-for="file in uploadFiles">
								<Progress :key="file.uuid" :file="file" :percentage="file.percentage"
									@retry="uploadRetry(file)" @remove="uploadRemove(file)"></Progress>
							</template>
						</div>
						<div v-else class="empty">
							<img :src="assets.emptyImg" class="empty-img" />
							<div class="empty-description">
								暂无附件内容~
							</div>
						</div>
					</div>
				</el-col>
			</el-row>

		</div>
	</div>
</template>

<script>
	import Upload from '@/components/Upload'
	import UploadOss from '@/components/Upload/oss'
	import kong from '@/assets/images/kong.png'
	import Progress from '@/components/Progress'
	import {
		debounce
	} from '@/utils/debounce'

	export default {
		components: {
			Upload,
			Progress,
			UploadOss
		},
		data() {
			return {
				// 公告ID
				id: '',
				limit: 100,
				cached: {
					pageNo: 1,
					searchKey: '',
					modelType: '',
					deviceType: '',
					deviceName: '',
					materialType: ''
				},
				form: {
					modelType: '', // 机种分类
					deviceIdList: [],
					materialType: '', // 资料类型
					materialName: '', // 资料名称
					fileIdList: [], // 上传文件ID列表
					firmwareList: [],
					remark: ''
				},
				downloadItem: {
					firmwareName: '',
					firmwareLink: ''
				},
				firmwareRules: {
					firmwareLink: [{
						type: 'url',
						message: '请输入正确的地址',
						trigger: 'blur'
					}]
				},
				// 机型选项
				machineProps: {
					multiple: true,
					emitPath: false,
					value: 'id',
					label: 'name',
					children: 'children'
				},
				// 机型数据
				modelDevices: [],
				// 机型分类+机型名称数据
				deviceOptions: [],
				// 资料类型选项
				resourceTypeOptions: [],
				// 对应el-upload组件的数据
				// 当重新上传时，会清空组件的文件列表数据
				// 正常的上传状态文件列表
				uploadNormalFiles: [],
				// 失败的上传文件列表
				uploadFailedFiles: [],
				// 静态文件
				assets: {
					emptyImg: kong
				},
				rules: {
					modelType: [{
						required: true,
						message: '请选择机种分类',
						trigger: 'change'
					}],
					deviceIdList: [{
						required: true,
						message: '请选择机型名称',
						trigger: 'change'
					}],
					materialType: [{
						required: true,
						message: '请选择资料类型',
						trigger: 'change'
					}],
					materialName: [{
							required: true,
							message: '请输入资料名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 32,
							message: '资料名称长度须在1-32范围内',
							trigger: 'blur'
						}
					],
					// fileIdList: [{
					// 	required: true,
					// 	message: '请上传附件',
					// 	trigger: 'change'
					// }]
				},
				// 吸顶标题隐藏
				scrollTop: 0,
				deviceVisible: false,
				// 添加一个固件下载禁止上传附件
				uploadDisabled: false
			}
		},
		watch: {
			uploadNormalFiles(files) {
				this.form.fileIdList = []
				for (const file of files) {
					if (file.response && file.response.code === '0') {
						this.form.fileIdList.push(file.response.data.id)
					}
				}
			},
			uploadFailedFiles(files) {},
			modelType(val) {
				this.form.deviceIdList = []
				let options = []
				for (const model of this.modelDevices) {
					if (val.includes(model.id)) {
						options = options.concat(model.children);
					}
				}
				// this.deviceOptions = options
				this.$nextTick(() => {
					this.$refs['form'].clearValidate()
					this.deviceOptions = options
				})
			},
			modelDevices(devices) {
				if (this.form.modelType) {
					let options = []
					for (const model of devices) {
						// if (model.id === this.form.modelType) {
						// 	options = model.children || []
						// 	break
						// }
						if (this.form.modelType.includes(model.id)) {
							options = options.concat(model.children);
						}
					}
					this.deviceOptions = options
				}
			}
		},
		computed: {
			// 所有状态的上传文件
			uploadFiles() {
				let files = []

				if (this.uploadNormalFiles) files.push.apply(files, this.uploadNormalFiles)
				if (this.uploadFailedFiles) files.push.apply(files, this.uploadFailedFiles)
				return files
			},
			modelType() {
				return this.form.modelType
			},
			title() {
				if (this.id) return '修改资料'
				return '新增资料'
			},
			deviceClass() {
				if (this.deviceVisible) return 'hide-cascader-scroll'
				return 'hide-cascader-scroll-reset'
			}
		},
		created() {
			this.getResourceType()
			this.getModelDevice()

			this.id = this.$route.query.id

			if (this.id) this.getDetail()

			this.cached.pageNo = this.$route.params.pageNo || 1
			this.cached.searchKey = this.$route.params.searchKey || ''
			this.cached.modelType = this.$route.params.modelType || ''
			this.cached.deviceType = this.$route.params.deviceType || ''
			this.cached.deviceName = this.$route.params.deviceName || ''
			this.cached.materialType = this.$route.params.materialType || ''
		},
		methods: {
			// 获取公告数据
			getDetail() {
				if (!this.id) return

				let that = this
				that.$request.post(
					"dataLibraryInfo",
					true, {
						id: that.id
					},
					function (r) {
						if (r.code == "0") {
							that.form.modelType = r.data.modelId
							that.form.materialType = r.data.materialType
							that.form.materialName = r.data.materialName
							that.form.remark = r.data.remark

							that.$nextTick(() => {
								that.form.deviceIdList = r.data.deviceIdList
							})
							that.form.firmwareList = []
							for (const firmware of r.data.firmwareList) {
								const f = {
									firmwareName: firmware.firmwareName,
									firmwareLink: firmware.firmwareLink
								}
								that.form.firmwareList.push(f)
							}
							if(that.form.firmwareList.length > 0) {
								that.uploadDisabled = true;
							}
							if(that.form.materialType == '1428012982556266497'){
								that.limit = 1;
							}else{
								that.limit = 100;
							}
							that.uploadNormalFiles = []
							for (const file of r.data.fileList) {
								file.name = file.fileName
								file.status = 'success'
								file.percentage = 100
								file.uid = ''
								file.response = {
									code: '0',
									data: {
										id: file.id
									}
								}
								that.uploadNormalFiles.push(file)
							}
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取资料类型
			getResourceType() {
				let that = this
				that.$request.post(
					"sysDicCodeList",
					true, {
						code: 'resourceType'
					},
					function (r) {
						if (r.code == "0") {
							that.resourceTypeOptions = r.data
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 获取所有机型数据
			getModelDevice() {
				let that = this
				that.$request.post(
					"sysModelDevice",
					true, {},
					function (r) {
						if (r.code == "0") {
							that.modelDevices = r.data
						} else {
							that.$message.error(r.msg);
						}
					}
				)
			},
			// 资料类型变更
			handleGujian() {
				// this.downloadItem.firmwareName = '';
				// this.downloadItem.firmwareLink = '';
				// this.form.firmwareList = [];
				// this.form.fileIdList = [];
				// this.uploadDisabled = false;
				// this.uploadNormalFiles = [];
				if(this.form.materialType == '1428012982556266497'){
					this.limit = 1;
				}else{
					this.limit = 100;
				}
			},
			// 添加固件下载地址
			addDownloadItem() {
				if(this.form.fileIdList.length > 0) {
					this.$message.error('添加附件后禁止填写固件下载')
					return
				}
				this.uploadDisabled = true;
				if(this.form.firmwareList.length > 0) {
					this.$message.error('仅支持添加一个固件')
					return
				}
				this.downloadItem.firmwareName = this.downloadItem.firmwareName.trim()
				this.downloadItem.firmwareLink = this.downloadItem.firmwareLink.trim()

				this.$refs['firmware-form'].validate((valid) => {
					if (valid) {
						const obj = Object.assign({}, this.downloadItem)
						this.form.firmwareList.push(obj)
						this.downloadItem.firmwareName = ''
						this.downloadItem.firmwareLink = ''
					} else {
						return false
					}
				})
			},
			// 删除固件下载地址
			removeDownloadItem(item) {
				this.uploadDisabled = false;
				this.form.firmwareList.splice(this.form.firmwareList.indexOf(item), 1)
			},
			handleUpload() {
				if(this.form.firmwareList.length > 0) {
					this.$message.error('添加固件下载后禁止上传附件');
				}
			},
			// 上传失败重试
			uploadRetry(file) {
				this.uploadFailedFiles.splice(this.uploadFailedFiles.indexOf(file), 1)
				this.uploadNormalFiles.splice(this.uploadNormalFiles.indexOf(file), 1)
				file.status = 'ready'
				this.uploadNormalFiles.push(file)
				this.$refs.upload.handleUpload({
					file: file.raw
				})
				// this.$refs.upload.submit()
			},
			// 取消上传
			uploadRemove(file) {
				// this.$refs.upload.abort(file)
				if (this.uploadNormalFiles.indexOf(file) > -1) {
					this.uploadNormalFiles.splice(this.uploadNormalFiles.indexOf(file), 1)
				}
				if (this.uploadFailedFiles.indexOf(file) > -1) {
					this.uploadFailedFiles.splice(this.uploadFailedFiles.indexOf(file), 1)
				}
			},
			// 新增|修改资料
			submit: debounce(function () {
				this.form.materialName = this.form.materialName.trim()
				if(this.form.materialType != '1428012982556266497' && this.form.fileIdList.length == 0) {
					this.$message.error('请上传附件');
					return
				}
				if(this.form.materialType == '1428012982556266497' && this.form.fileIdList.length == 0 && this.form.firmwareList.length == 0) {
					this.$message.error('请填写一个固件下载地址或者上传附件');
					return
				}
				this.$refs['form'].validate((valid) => {
					if (valid) {
						if (this.id) {
							// 修改
							const data = Object.assign(this.form, {
								id: this.id
							})
							let that = this
							delete data.modelType;
							that.$request.post(
								"dataLibraryUpdate",
								true,
								data,
								function (r) {
									if (r.code == "0") {
										that.$message.success('保存成功')
										that.$router.go(-1)
									} else {
										that.$message.error(r.msg)
									}
								}
							)
						} else {
							// 新增
							let that = this
							delete that.form.modelType;
							that.$request.post(
								"dataLibraryAdd",
								true,
								that.form,
								function (r) {
									if (r.code == "0") {
										that.$message.success('保存成功')
										that.$router.go(-1)
									} else {
										that.$message.error(r.msg)
									}
								}
							)
						}
					} else {
						return false
					}
				})
			}, 1000),
			goBack() {
				this.$router.push({
					name: 'library',
					params: {
						pageNo: this.cached.pageNo,
						searchKey: this.cached.searchKey,
						modelType: this.cached.modelType,
						deviceType: this.cached.deviceType,
						deviceName: this.cached.deviceName,
						materialType: this.cached.materialType
					}
				})
			},
			// 解决cascader组件margin-bottom: -17px 失效的问题
			deviceCascaderVisableChange(visible) {
				this.deviceVisible = visible
			},
			// 处理吸顶时title隐藏
			handleScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			}
		},
		mounted() {
			window.addEventListener('scroll',this.handleScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll)
		}
	}

</script>

<style lang="scss" scoped>
	@media screen and (max-width: 767px) {
		.content-row {
			flex-direction: column;
		}
	}

</style>
